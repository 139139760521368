import * as Sentry from '@sentry/gatsby';

Sentry.init({
  dsn: 'https://9b89a88df9014c078f0b906075daf1b2@o199827.ingest.us.sentry.io/1310412',
  // release: `bw-fe-${process.env.GATSBY_LOCALE}@${new Date().toISOString()}`,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
